<app-header></app-header>
<div class="banner-img-name"></div>

<div id="" class="center_body">
    <div class="card p10">




      <div style="border-radius:200px; height:100px; width:100px; background: #F8FAF5; margin:0 auto; border:1px solid #f0f0f0">
        <i class="fa fa-times" style="color: #dd1d1d; margin-left: 0;"></i>
    </div>
        <h2>Contactless Eligibility Failed</h2>
       <div class="mt30">
        You are not eligible for contactless enrollment and have already paid booklet fee. Kindly proceed to book appointment (if you have not done so) and visit your choosen passport procesing center for biometric capture
       </div>
        <div class="mt30">
            <a routerLink="/" class="custom_btn">Back to Home</a>
        </div>

    </div>
</div>
