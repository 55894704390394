<app-header></app-header>
<div class="banner-img-name"></div>

<div id="" class="center_body mt30">


    <div class="card">
        <div
            style="border-radius:200px; height:100px; width:100px; background: #F8FAF5; margin:0 auto; border:1px solid #f0f0f0">
            <i class="fa fa-times" style="color: #dd1d1d; margin-left: 0;"></i>
        </div>

        <h1 style="color: #dd1d1d; "> Payment Failed!</h1>

        <div class="info_paid">
            <span><b>Transaction Reference</b>
                {{this.referenceNumber}}
            </span>

            <span><b>Total Amount</b>
                $ 100
            </span>
        </div>

        <input type="button" (click)="openHomePage()" value="Go to Home" class="custom_btn">
    </div>

</div>
