import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Tags } from '../common/tags';
import { LocalStorage } from '../common/local-storage';
import { GUI } from '../common/gui';
import { environment } from 'src/environments/environment';
import { Utils } from '../common/utils';
import { Constants } from '../common/constants';
import { LoaderService } from '../common/loader-service';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { KeycloakProfile } from 'keycloak-js';
import { UserType } from '../common/user-type';
import { HttpUtils } from '../common/http-utils';
import { Toast } from '../common/toast';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  private isLoggedIn = false;

  private userProfile: KeycloakProfile | null = null;

  private loaderTaskId: string = 'LOGIN_';

  constructor(
    private readonly keycloakService: KeycloakService,
    private httpUtils: HttpUtils,
    private tags: Tags,
    private localStorage: LocalStorage,
    public gui: GUI,
    private utils: Utils,
    public constants: Constants,
    private loaderService: LoaderService,
    private userType: UserType,
    private toast: Toast
  ) {
    this.loaderService.startLoaderV2(this.loaderTaskId + '1');
  }

  async ngOnInit() {
    this.isLoggedIn = await this.keycloakService.isLoggedIn();
    if (this.isLoggedIn == true) {
      // console.log(
      //   'toekn from login token22222',
      //   this.keycloakService.getKeycloakInstance().token
      // );
      Cookie.set(
        this.tags.KEYCLOAK_TOKEN,
        this.keycloakService.getKeycloakInstance().token,
        1,
        environment.path,
        environment.domain
      );
      Cookie.set(
        this.tags.KEYCLOAK_REFRESH_TOKEN,
        this.keycloakService.getKeycloakInstance().refreshToken,
        1,
        environment.path,
        environment.domain
      );

      this.userProfile = await this.keycloakService.loadUserProfile();
      // this.userProfile = {
      //   "username": "7ccg3x2w9f@bltiwd.com",
      //   "firstName": "chris",
      //   "lastName": "Chukwu",
      //   "email": "7ccg3x2w9f@bltiwd.com",
      //   "emailVerified": true,

      // };

      const userTypeArray =
        this.keycloakService.getKeycloakInstance().realmAccess?.roles;
      if (this.userType.isValidUser(userTypeArray) === false) {
        this.loaderService.stopLoaderV2(this.loaderTaskId + '1');
        this.gui.openInvalidAccess();
        return;
      }
      // this.gui.openNinVerificationPage(this.constants.REISSUE)

      const userId =
        this.keycloakService.getKeycloakInstance().refreshTokenParsed?.sub;
      this.fetchServerToken(userId, userTypeArray);
    } else {
      this.loaderService.stopLoaderV2(this.loaderTaskId + '1');
      this.gui.openNewHomePage();
    }
  }

  private fetchServerToken(userId: string, userTypeArray: any[]): void {
    this.httpUtils.fetchServerToken().then((serverResponse) => {
      if (serverResponse.responseCode === this.constants.STATUS_SUCCESS) {
        this.localStorage.setStorageKey(
          this.tags.APPLICATION_TOKEN,
          serverResponse.bearerToken
        );
        this.saveLoginDetails(userId, userTypeArray);
      } else {
        this.loaderService.stopLoaderV2(this.loaderTaskId + '1');
        this.toast.showErrorToast(serverResponse.responseMsg);
        this.gui.openLogoutPage();
      }
    });
  }

  // private fetchServerToken(userId: string, userTypeArray: any[]): void {
  //   this.localStorage.setStorageKey(this.tags.APPLICATION_TOKEN, "TvfaL/hFHZFEgPMJXLr11YjxflOCv1FNJjfEDglV2Rs=");
  //   this.saveLoginDetails(userId, userTypeArray);
  //   this.loaderService.stopLoaderV2(this.loaderTaskId + '1');

  // }

  // private saveLoginDetails(userId: string, userTypeArray: any[]): void {
  //   console.log("userId", userId)
  //   console.log("userTypeArray", userTypeArray)

  //   const loggedUserId = this.utils.encryptString(userId);
  //   const loggedUserFullName = this.utils.encryptString(this.userProfile.firstName + this.userProfile.lastName);

  //   console.log("fullname", loggedUserFullName)
  //       this.localStorage.setStorageKey(this.tags.LOGGED_USER_ID, loggedUserId);
  //       this.localStorage.setStorageKey(this.tags.LOGGED_USER_NAME, loggedUserFullName);

  //       const userTypeJSONObject = { [this.tags.USER_TYPE_JSON_KEY]: userTypeArray };
  //       this.localStorage.setStorageKey(this.tags.LOGGED_USER_TYPE, this.utils.encryptString(JSON.stringify(userTypeJSONObject)));
  //       // this.httpUtils.saveLoginDetails(userId, this.userProfile.email, this.userProfile.username, this.userProfile.firstName, this.userProfile.lastName);
  //       const redirectRouteName = this.localStorage.getLoggedUserCurrentRoute();
  //       this.gui.redirectControl(redirectRouteName);

  //   // this.httpUtils.saveLoginDetails(userId, this.userProfile.email, this.userProfile.username, this.userProfile.firstName, this.userProfile.lastName).then(serverResponse => {
  //   //   this.loaderService.stopLoaderV2(this.loaderTaskId + '1');
  //   //   if (serverResponse.responseCode === this.constants.STATUS_SUCCESS) {
  //   //     const loggedUserId = this.utils.encryptString(serverResponse.userId);
  //   //     const loggedUserFullName = this.utils.encryptString(serverResponse.userFullName);

  //   //     this.localStorage.setStorageKey(this.tags.LOGGED_USER_ID, loggedUserId);
  //   //     this.localStorage.setStorageKey(this.tags.LOGGED_USER_NAME, loggedUserFullName);

  //   //     const userTypeJSONObject = { [this.tags.USER_TYPE_JSON_KEY]: userTypeArray };
  //   //     this.localStorage.setStorageKey(this.tags.LOGGED_USER_TYPE, this.utils.encryptString(JSON.stringify(userTypeJSONObject)));

  //   //     const redirectRouteName = this.localStorage.getLoggedUserCurrentRoute();
  //   //     // this.gui.redirectControl(redirectRouteName);
  //   //   } else {

  //   //     const redirectRouteName = this.localStorage.getLoggedUserCurrentRoute();
  //   //     this.gui.redirectControl(redirectRouteName);
  //   //     // this.toast.showErrorToast(serverResponse.responseMsg);
  //   //     // this.gui.openLogoutPage();
  //   //   }
  //   // });
  // }

  private saveLoginDetails(userId: string, userTypeArray: any[]): void {
    this.httpUtils
      .saveLoginDetails(
        userId,
        this.userProfile.email,
        this.userProfile.username,
        this.userProfile.firstName,
        this.userProfile.lastName
      )
      .then((serverResponse) => {
        this.loaderService.stopLoaderV2(this.loaderTaskId + '1');
        if (serverResponse.responseCode === this.constants.STATUS_SUCCESS) {
          const loggedUserId = this.utils.encryptString(serverResponse.userId);
          const loggedUserFullName = this.utils.encryptString(
            serverResponse.userFullName
          );

          this.localStorage.setStorageKey(
            this.tags.LOGGED_USER_ID,
            loggedUserId
          );
          this.localStorage.setStorageKey(
            this.tags.LOGGED_USER_NAME,
            loggedUserFullName
          );

          const userTypeJSONObject = {
            [this.tags.USER_TYPE_JSON_KEY]: userTypeArray,
          };
          this.localStorage.setStorageKey(
            this.tags.LOGGED_USER_TYPE,
            this.utils.encryptString(JSON.stringify(userTypeJSONObject))
          );

          const redirectRouteName =
            this.localStorage.getLoggedUserCurrentRoute();
          this.gui.redirectControl(redirectRouteName);
        } else {
          this.toast.showErrorToast(serverResponse.responseMsg);
          this.gui.openLogoutPage();
        }
      });
  }
}
