<app-header></app-header>
<div class="banner-img-name"></div>

<div id="" class="center_body mt30">


    <div class="card">
        <div
            style="border-radius:200px; height:100px; width:100px; background: #f2f9e8; margin:0 auto; border:1px solid #f0f0f0">
            <!-- <i class="checkmark">✓</i> -->
            <!-- <i class="fa fa-check-circle" aria-hidden="true"></i> -->
            <img src="assets/images/tick.png" alt="sucess" style="width:57px;padding-top:20px;">
        </div>

        <h1> Payment Successful!</h1>

        <div class="info_paid">
            <span><b>Transaction Reference</b>
                {{this.referenceNumber}}
            </span>

            <span><b>Contactless Passport Application Fee</b>
                $ 100
            </span>
        </div>

        <input type="button" (click)="openHomePage()" value="Go to Home" class="custom_btn">
    </div>

</div>
