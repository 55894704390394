import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Constants } from '../common/constants';
import { GUI } from '../common/gui';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { LocalStorage } from '../common/local-storage';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';
import { ActivatedRoute, Params } from '@angular/router';
import { Tags } from '../common/tags';

@Component({
  selector: 'app-payment-success-v1',
  templateUrl: './payment-success-v1.component.html',
  styleUrls: ['./payment-success-v1.component.css']
})
export class PaymentSuccessV1Component {

  paidApplicationsList : any = [];

  paymentMessage : any = {};

  isServerResponseCome : boolean = false;

  paymentData: string = this.constants.BLANK;

  constructor(private activatedRoute: ActivatedRoute, private httpUtils: HttpUtils, private toast: Toast, public constants: Constants, private loaderService: LoaderService, private localStorage: LocalStorage,
              private gui: GUI, private utils: Utils, private tags: Tags) {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.paymentData = params['paymentData'];
    });
    this.validatePaymentData();
  }

  private validatePaymentData(): void {
    if(this.paymentData === this.constants.NOT_FOUND_KEY) {
      const redirectRouteName = this.constants.RESUME_APPLICATION;
      this.localStorage.setStorageKey(this.tags.LOGGED_USER_CURRENT_ROUTE, this.utils.encryptString(redirectRouteName));
      this.gui.openDashboardPage();
      return;
    }

    this.fetchUserPaidApplications();
  }

  private fetchUserPaidApplications(){
    this.loaderService.startLoader();
    const loggedUserId = this.localStorage.getLoggedUserId();
    this.httpUtils.fetchUserPaidApplications(loggedUserId, this.paymentData).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.paidApplicationsList = serverResponse.paymentApplications;
        this.paymentMessage = serverResponse.paymentMessage;
        this.isServerResponseCome = true;
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  openDashboard(){
    // this.gui.openDashboardPage();
    this.gui.openNewHomePage();
  }

  viewApplication(applicantId: string): void {
    this.gui.openApplicationViewPage(applicantId);
  }

  checkPaymentStatus(applicantId: string): void {
    this.loaderService.startLoader();
    const loggedUserId = this.localStorage.getLoggedUserId();
    this.httpUtils.checkPaymentStatusOnIOS(loggedUserId, applicantId, true).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.paidApplicationsList = serverResponse.paymentApplications;
        this.paymentMessage = serverResponse.paymentMessage;

        const checkPaymentResponseCode = serverResponse.checkPaymentResponseCode;
        const checkPaymentResponseMsg = serverResponse.checkPaymentResponseMsg;
        if(checkPaymentResponseCode === this.constants.STATUS_SUCCESS) {
          this.toast.showSuccessToast(checkPaymentResponseMsg);
        } else {
          this.toast.showErrorToast(checkPaymentResponseMsg);
        }
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  checkPaymentRefundStatus(applicantId: string, applicationIdV1: string, referenceIdV1: string){
    this.loaderService.startLoader();
    const loggedUserId = this.localStorage.getLoggedUserId();
    this.httpUtils.fetchRefundStatus(applicantId, loggedUserId).then(serverResponse => {
      this.loaderService.stopLoader();
      if (serverResponse.responseCode === this.constants.STATUS_SUCCESS && serverResponse.paymentRefunded === true){
        this.toast.showErrorToast(serverResponse.responseMsg);
        this.fetchUserPaidApplications();
      } else if(serverResponse.responseCode === this.constants.STATUS_SUCCESS && serverResponse.paymentRefunded === false) {
        this.openBookAppointmentURL(applicationIdV1, referenceIdV1);
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  openBookAppointmentURL(applicationIdV1: string, referenceIdV1: string){
    let appointmentURL = environment.appointmentApplicationUrl;

    if(this.utils.isEmpty(applicationIdV1) === false && this.utils.isEmpty(referenceIdV1) === false) {
      const base64String = btoa(applicationIdV1 + "|" + referenceIdV1);
      appointmentURL = appointmentURL + '?a=' + base64String;
    }

    window.open(appointmentURL, '_blank');
  }

}
