<app-header></app-header>
<div class="banner-img-name"></div>

<div id="" class="center_body wrapper">
    <div class="card p10 main">

        <form>
            <input type="hidden" value="{{applicationId}}" name="applicationId" />
            <input type="hidden" value="{{referenceId}}" name="referenceId" />
        </form>


        <div
            style="border-radius:200px; height:100px; width:100px; background: #e4f4cc; margin:0 auto; border:1px solid #f0f0f0">
            <i class="checkmark">✓</i>

        </div>
        <h2>Congratulations</h2>
        <div class="mt30">
            Your passport application has been successfully completed.<br />
            You may proceed to contactless biometric enrollment
        </div>
        <div class="mt30">
            <a routerLink="" class="custom_btn contactless-confirm">Proceed to Contactless Enrollment</a>
        </div>

    </div>
</div>