import { Component, NgZone, OnInit } from '@angular/core';
import { GUI } from '../common/gui';
import { LoaderService } from '../common/loader-service';
import { ActivatedRoute, Router } from '@angular/router';
import { Utils } from '../common/utils';
import { Route } from '../common/route';
import { Constants } from '../common/constants';
import { Toast } from '../common/toast';
import { HttpUtils } from '../common/http-utils';

@Component({
  selector: 'app-show-response',
  templateUrl: './show-response.component.html',
  styleUrls: ['./show-response.component.css']
})
export class ShowResponseComponent implements OnInit {
  applicationId: string = "";
  referenceId: string = "";

  constructor(private router: Router, private ngZone: NgZone, private activatedRoute: ActivatedRoute, private httpUtils: HttpUtils, private toast: Toast, public constants: Constants,
    private utils: Utils, private loaderService: LoaderService, private route: Route,    public gui: GUI) {
  }

  private getApplicationInfo(): void {
    this.applicationId = JSON.parse(localStorage.getItem("applicationId")) || null;
    this.referenceId = JSON.parse(localStorage.getItem("referenceId")) || null;
    this.loaderService.stopLoader();
    if (this.utils.isEmpty(this.applicationId) || this.utils.isEmpty(this.referenceId)) {
      this.gui.openDashboardPage();
    }
  }

  ngOnInit(): void {
    this.getApplicationInfo();
  }

  ngOnDestroy(): void {
    this.clearApplicationInfo();
  }

  private clearApplicationInfo(): void {
    localStorage.removeItem("applicationId");
    localStorage.removeItem("referenceId");
    localStorage.removeItem("contactlessStatus");
  }
}
